accountSelector = {
  submit: function submit(accountnumber) {
    var postEvent = {
      scAction: "SetSubAccount",
      scController: "EDometic",
      contextItem: $("body").data("sc-item"),
      contextLanguage: $("body").data("sc-language"),
      accountnumber: accountnumber
    };
    $.ajax({
      method: "POST",
      url: "?r=".concat(Math.random()),
      data: postEvent
    }).done(function (result) {
      window.location = result.url;
    });
  }
};